<template>
    <div class="detail-container">
        <div class="detail-content">
            <div v-if="detailInfo" class="form-row">
                <div class="form-group col-md-4">
                    <label for="codeItem">코드 항목</label>
                    <input v-model="detailInfo.codeItem" type="text" class="form-control" id="codeItem"
                        :disabled="!isEditMode">
                </div>
                <div class="form-group col-md-5">
                    <label for="codeName">코드명</label>
                    <input v-model="detailInfo.codeName" type="text" class="form-control" id="codeName"
                        :disabled="!isEditMode">
                </div>
                <div class="form-group col-md-3">
                    <label for="enabled">사용 여부</label>
                    <select v-model="detailInfo.enabled" id="enabled" class="form-control" :disabled="!isEditMode">
                        <option value="">선택</option>
                        <option v-for="code in $store.state.commonCodes.enabled" :key="code.value" :value="code.value">
                            {{ code.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div v-if="detailInfo" class="form-row">
                <div class="form-group col-md-12">
                    <label for="desc">설명</label>
                    <textarea v-model="detailInfo.desc" type="text" class="form-control" id="desc"
                        :disabled="!isEditMode" rows="2" style="resize: none;"></textarea>
                </div>
            </div>
            <div v-if="detailInfo" class="form-row">
                <div class="input-group col-md-12  mb-2" >
                    <span class="input-group-text">{{ data.etcInfo1 ? data.etcInfo1 : '추가속성1' }}</span>
                    <input v-model="detailInfo.etcInfo1" type="text" class="form-control" id="etcInfo1"
                        :disabled="!isEditMode">
                </div>
                <div class="input-group col-md-12  mb-2" >
                    <span class="input-group-text">{{ data.etcInfo2 ? data.etcInfo2 : '추가속성2' }}</span>
                    <input v-model="detailInfo.etcInfo1" type="text" class="form-control" id="etcInfo1"
                        :disabled="!isEditMode">
                </div>
                <div class="input-group col-md-12  mb-2" >
                    <span class="input-group-text">{{ data.etcInfo3 ? data.etcInfo3 : '추가속성3' }}</span>
                    <input v-model="detailInfo.etcInfo1" type="text" class="form-control" id="etcInfo1"
                        :disabled="!isEditMode">
                </div>
                <div class="input-group col-md-12  mb-2" >
                    <span class="input-group-text">{{ data.etcInfo4 ? data.etcInfo4 : '추가속성4' }}</span>
                    <input v-model="detailInfo.etcInfo1" type="text" class="form-control" id="etcInfo1"
                        :disabled="!isEditMode">
                </div>
                <div class="input-group col-md-12  mb-2" >
                    <span class="input-group-text">{{ data.etcInfo5 ? data.etcInfo5 : '추가속성5' }}</span>
                    <input v-model="detailInfo.etcInfo1" type="text" class="form-control" id="etcInfo1"
                        :disabled="!isEditMode">
                </div>
            </div>

        </div>
    </div>
</template>

<script>
// import backEndApi from "@src/api/backEndApi";

export default {
    props: ['data', 'isEditMode'],
    components: {
    },
    data() {
        return {
            detailInfo: null,
            // mode: 'view', // 기본 모드 설정
        }
    },
    computed: {
        // isEditMode() {
        //     return this.mode !== 'view';
        // },
    },
    created() {
        this.detailInfo = this.initData();
    },
    mounted() {
    },
    destroyed() { },
    methods: {
        initData() {
            return {
                codeItem: "",
                codeName: "",
                etcInfo1: "",
                etcInfo2: "",
                etcInfo3: "",
                etcInfo4: "",
                etcInfo5: "",
                enabled: "Y",
                desc: "",
            }
        },
        setData(data) {
            if (data) {
                this.mode = 'view';
                this.detailInfo = {...data};
            } else {
                this.mode = 'edit';
                this.detailInfo = this.initData();
            }

        },
        getData() {
            return this.detailInfo;
        },
    },
}
</script>

<style scoped>
.detail-container {
    height: 100%;
    border-radius: 8px;
    border: solid #eee 1px;
    font-size: 12px;
    /* overflow: hidden; */
}

.detail-placeholder {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.detail-header {
    background-color: #fff;
    border-radius: 8px 8px 0px 0px;
    padding: 0.8rem 1rem;
    /* margin: 1rem 0rem; */
}

.detail-content {
    height: 88%;
    width: 100%;
    border-radius: 0px 0px 8px 8px;
    border: solid #eee 1px;
    padding: 1rem 1rem;
    /* overflow-y: auto; */
}

.detail-bottom {
    display: flex;
    justify-content: space-between;
}
</style>